.pagination {
    display: inline-block;
    padding: 0;
    margin: 0;

    li {
        display: inline-block;
    }

    a {
        display: block;
        cursor: pointer;
        padding: 7px;
        font-size: 13px;
    }

    .active {
        background-color: #333;

        a {
            color: #fff;
        }
    }

}