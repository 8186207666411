form {

    .formGroup {

        margin: 10px 0;
        color: $black;

    }

    label {
        font-weight: 700;
        font-size: 0.8em;
        margin: 5px 0;
        display: block;

        &::first-letter {
            text-transform: uppercase;
        }
    }

    input,
    select,
    textarea {
        font-family: 'Inter', sans-serif;
        width: 100%;
        width: -webkit-fill-available;
        padding: 8px;
        border: 1px solid rgb(189, 189, 189);
        border-radius: 4px;
        color: #7c7c7c;
        line-height: 1;
        background: rgb(248, 248, 248);
    }

    input[type=checkbox] {
        width: auto;
    }

    button {
        width: 100%;
        color: $white;

        padding: 15px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        font-weight: 700;
    }
}

.form-inline {

    .formGroup {
        display: flex;
        //justify-content: space-between;
        align-items: flex-start;
        width: 100%;
        margin: 15px 0;
    }

    label {
        flex: 1 0;
        
        ::first-letter {
            text-transform: uppercase;
        }
    }

    input, textarea {
        flex: 6;
        padding: .5em;
    }

    input[type=radio] {
        flex:1;
        width: 10%;
        box-shadow: none;
    }

}