@import "colors.scss";
//@import "Dots.scss";
@import "menu.scss";
@import "buttons.scss";
@import "Tables.scss";
@import "DatePicker.scss";
@import "Card.scss";
@import "Alerts.scss";
@import "Forms.scss";
@import "dropdown.scss";
@import "Responsive.scss";
@import "Pagination.scss";
@import "Search.scss";

html,
body {
  overflow: auto;
}

body {
  min-height: 100%;
}

svg {
  display: block;
  margin: 0 auto;
}

body {
  font-family: 'Inter', sans-serif;
  
  margin: 0;
  padding: 0;
  font-size: 1rem;

  color: $black;
  width: 100%;

  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-position: 0 100%;
  background-color: $bgColor;
  background-repeat: no-repeat;
  background-size: cover;
}

.wrap {
  margin-top: 100px;
}

.container {
  max-width: 1200px;
  padding: 20px 0;
  margin: auto;
}

.show {
  opacity: 1;
  -webkit-transition: -webkit-transform 0.4s, opacity 0.4s;
  transition: transform 0.4s, opacity 0.4s;
}

.hidden {
  opacity: 0;
  -webkit-transition: -webkit-transform 0.4s, opacity 0.4s;
  transition: transform 0.4s, opacity 0.4s;
}

a {
  color: #1c2b33;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Montserrat', sans-serif;
}

.info-cpe {

  margin-top: 30px;

  h1 {
    font-size: 26px;
    color: $black;
    display: flex;
    align-items: center;
    padding: 0;
    margin: 10px 0;

    svg {
      margin: 0 5px;
    }
  }


  h2 {
    display: flex;
    font-size: 20px;
    padding: 0;
    margin: 0 0 10px 0;
    color: $primaryColor;
    font-weight: 300;

    svg {
      margin: 0 11px;
    }
  }

  h3 {
    font-size: 16px;
    margin: 0 0 10px 0;
    color: $secondaryColor;
    font-weight: 400;
  }

}

.layout-main {
  width: 100%;
  padding: 0 10px;
}

.footer {
  width: 100%;
  bottom: 0;
  font-size: 0.8em;
  padding: 40px 0;
  color: $white;
  background-color: #22353f;
}

.footer-links {
  color: $white;
  font-size: 0.8em;
  background-color: #344650;

  a {
    color: $white;
  }
}

.pull-rigth {
  float: rigth;
}

.breadcrumbs {
  padding: 20px 0;
  font-size: 13px;
  border-bottom: 1px solid rgb(238, 238, 238);
  background-color: $breadcrumbsBgColor;

  a {
    text-decoration: none;
  }
}

.attachments{
  padding: 30px 0;
  border-top: 1px solid rgb(238, 238, 238);
}

.well {
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  background-color: $wellBgColor;
  box-shadow: 0px 3px 15px -6px rgba(0, 0, 0, 0.15);
}

.well-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 10px 0;
  margin: 15px 0;
  border-bottom: 1px solid $grey;

  h3 {
    margin: 0;
    padding: 0;
  }

  .DayPickerInput {
    padding: 0;
  }

  .well-title {
    width: 50%;
  }
}

.well-dashboard {
  font-weight: 700;
  text-align: center;
  margin-bottom: 10px;
  color: $black;

  p {
    margin: 10px 0;
  }

  b {
    color: $black;
  }

  .card {
    position: relative;
    height: 100%;

    border-radius: .4rem;
    overflow: hidden;
    cursor: pointer;

    display: block;

    border-radius: 6px;
    background-color: $white;

    .bg::before {
      position: absolute;
      left: 15px;
      top: 15px;
      content: '';
      width: 8px;
      height: 8px;
      border-radius: 40px;

    }
  }

  .horario .bg::before {
    background-color: #ffe147;
  }

  .tensao .bg::before {
    background-color: #f36031;
  }

  .tarifa .bg::before {
    background-color: #cd7295;
  }

  .ciclo .bg::before {
    background-color: #85ccf1;
  }

}



section {
  color: #333;
}

.bigBtn {
  margin: 20px 0;
}

.bigBtn a {
  color: $white;
  cursor: pointer;
  overflow: hidden;
  border-radius: 4px;
  margin: 0 0 10px 0;
  padding: 10px 10px;
  text-decoration: none;
  border: 1px solid rgba(248, 80, 50, 1);
  color: #fff;
  text-align: center;
  background-color: #eee;
  font-size: 14px;
  background-color: #f1363c;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.35);
}

.bigBtn:hover span {
  animation: shake 0.92s cubic-bezier(.36, .07, .19, .97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {

  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-3px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(3px, 0, 0);
  }
}

.btn-logout {
  background: none;
  color: inherit;
  border: none;
  font-weight: 700;
  cursor: pointer;
}

.ball {
  display: inline-block;
  width: 12px;
  height: 12px;

  background-size: cover;
}

.home {
  padding: 30px 0;
  line-height: 28px;
}

.content {
  margin: 0;
  padding: 60px 0;
  line-height: 28px;

  p {
    margin: 0 0 20px 0;
  }
}


.bigBtn:hover .bigBtn-bg {
  top: -90px;
  left: -110px;
}

.bigBtn-icon {
  margin: 15px 0 5px 0;
  padding: 5px;
}

.font-size-90 {
  font-size: 90%;
  line-height: 21px;
}

.font-size-95 {
  font-size: 95%;
  line-height: 24px;
}

.margin-5 {
  margin: 5px;
}

.margin-10 {
  margin: 10px;
}

.margin-20 {
  margin: 20px;
}

.margin-tb-5 {
  margin: 5px 0;
}

.margin-tb-10 {
  margin: 10px 0;
}

.margin-tb-20 {
  margin: 20px 0;
}

.margin-tb-40 {
  margin: 40px 0;
}

.padding-0 {
  padding: 0;
}

.padding-20 {
  padding: 20px;
}

.padding-tb-10 {
  padding: 10px 0;
}

.padding-tb-20 {
  padding: 20px 0;
}

.padding-tb-40 {
  padding: 40px 0;
}

.img-responsive {
  max-width: 100%;
  height: auto;
}

.img-border-radius {
  border-radius: 10px;
}

.center-block {
  display: block;
  margin: auto;
}

.bullet-list {

  ul {
    
    margin: 0;
    padding: 0 20px;

    li {
      margin: 15px 0;
      font-weight: 700;
    }

    /*
    li:before {
      content: '>';
      padding-right: 10px;
    }
    */

    li::marker {
      color: rgb(101, 150, 103);
    }

  }
}

table {
  width: 100%;
}

th,
td {
  text-align: center;
}

.text-c {
  text-align: center !important;
}

.text-r {
  text-align: right !important;
}

.modal-open {
  overflow: hidden;
}

.modal-close {
  overflow: auto;
}

.cursor {
  cursor: pointer;
}

.marcas {
  padding: 50px 0;
  width: 100%;
  border-top: 1px solid rgb(228, 228, 228);

  .logos {
    display: flex;
    justify-content:space-between;
  }
}

.item-brand {
  display: flex;
  align-items: center;

  img {
    margin-left: 5px;
  }
  
}

.contact-box {
  width: 100%;
  margin: auto;
  padding: 40px 0;
  text-align: center;
  line-height: 29px;
  color: #fff;
  background-color: #1c2b33;

  a {
    color: white;
  }

  .text {
    padding: 50px;
    text-align: right;

    .h4 {
      font-size: 2.5em;
    }
  }
}

.email-box {
  width: 100%;
  padding: 60px 0;
  background-color: $emailBoxBgColor;
  text-align: center;
  color: #62866a;
  background-image: url("../../img/img-esquerda.png");
}

.text_block {
  position: relative;
  z-index: 10;
}

.bgImage {
  position: relative;

  & > ::before {
    filter: blur(1px);
    opacity: 0.1;
    z-index: 1;
    position: absolute;
    top:0;
    left: 0;
    content: "";
    display: block;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: top center;
  }

}

.dark-green {
  color: white;
  background: #263b3c;

  a {
    color: white;
  }

  .btn-square {
    border-color: white;
  }
}

.instalations_bg {
  & > ::before {
    background-image: url("../../img/pages/interior-1.jpg");
  }
}

.quality_bg {
  & > ::before {
    background-image: url("../../img/pages/6.jpg");
  }
}

.resources_bg {
  & > ::before {
    background-image: url("../../img/pages/recursos11.jpg");
  }
}

.equipamentos_bg {
  & > ::before {
    background-image: url("../../img/pages/recursos3.jpg");
  }
}

.competencias_bg {
  & > ::before {
    background-image: url("../../img/pages/As_Nossas Competencias_Tecnologia_imagem.jpg");
  }
}

.videos {
  position: relative;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 600px;
  overflow: hidden;
  margin: 0;

  .title {
    position: absolute;
    color: #fff;
    text-align: center;
    padding: 30px;
    font-size: 700;
    font-size: 1em;

    background-color: rgba($color: #000000, $alpha: 0.3);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  video, img {
    /* Make video to at least 100% wide and tall */
    min-width: 100%;
    min-height: 100%;

    /* Setting width & height to auto prevents the browser from stretching or squishing the video */
    width: auto;
    height: auto;

    /* Center the video */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

  }
}

.carousel_thumb {
  cursor: pointer;
    padding: 0;
    margin: 0 0 0 5px;
    border: none;
    background: no-repeat;
  }