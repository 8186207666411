/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {

  //CSS

}

/* 
    ##Device = Laptops, Desktops
    ##Screen = B/w 1025px to 1280px
  */

@media (min-width: 1025px) and (max-width: 1280px) {

  //CSS

}

/* 
    ##Device = Tablets, Ipads (portrait)
    ##Screen = B/w 768px to 1024px
  */

@media (min-width: 768px) and (max-width: 1024px) {

  //CSS

}

/* 
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
  */

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {

  //CSS

}

/* 
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
  */

@media (min-width: 481px) and (max-width: 767px) {

  //CSS

}

/* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */

@media (min-width: 320px) and (max-width: 480px) {


  .responsive {
    display: none !important;
  }

  body {
    padding: 0;
  }

  .videos {
    height: 350px !important;
    .title {
      width: 100%;
      
      h1 {
        font-size: 1em;
      }
    }
  }

  .mobile-menu {
    width: 100%;
    position: fixed;
    bottom: 0;
    background-color: #333;
    border-radius: 0;
    box-shadow: 0px -6px 21px -10px rgba(0,0,0,0.55);

    ul {
      display: flex;
      list-style-type: none;
      align-items: center;
      justify-content: space-evenly;
      margin: 0;
      padding: 15px 0;
    }

    li {
      text-align: center;
      margin: 0;
      padding: 0;
      font-size:1.5vh;
      color: #333;
      

      img {
        display: block;
        margin: 5px auto;
      }

      svg {
        display: block;
        margin: 5px auto;
      }

      a {
        color: rgb(170, 168, 182);
        text-align: center;
      }

      div {
        color: rgb(170, 168, 182);
        text-align: center;
      }
    }
  }

  .container {
    padding: 0 20px !important;
  }

  .items-toolbar {
    padding: 20px 0;
  }

  .header {
    height: auto;
    margin: 0 !important;
    padding: 0 !important;


    .header-container {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      margin: 0;
      border-radius: 0;
      box-shadow: none;
      background: none;

    }

    .menu {
      display: none;
    }

    .hamburger-menu {
      display: block !important;

      .menu {
        display: block !important;

        li {
          padding: 0;
        }

        a {
          padding: 20px 0;
        }

        button {
          margin: 20px 0;
          padding: 15px;
          background: white;
          border-radius: 0;
          border: none;
          text-transform: capitalize;
        }
      }
    }
 

    li {
      justify-content: start;
      border-bottom: 1px solid rgba(128, 11, 93, 0.15);
    }

    .logo {
      background: transparent;

    }

  }

  .marcas {
  
    .logos {
      flex-direction: column;
      align-items: center;
    }
  }

  .info-cpe {
    position: relative;
    margin: 0 0 25px 0 !important;
    padding: 20px 0;
    color: #fff;
    text-align: center;
  }

  .item-detail {
    padding: 10px;
  }

  .cpe-list {
    margin: 0;
  }

  .form-inline {
    .formGroup {
        display: block;
    } 
  }

  .table {

    th {
      font-size:11px;
    }

    td {
      font-size:12px;
    }
    
  }

}

@media (min-width:240px) and (max-width:320px) {
  
  

}

