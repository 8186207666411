.card {
    text-align: center;
    background-color: #fff;
    padding: 10px;
    border-radius: 20px;
    overflow: hidden;
    cursor: pointer;
    transition: 0.2s;
    box-shadow: 0px 3px 15px -6px rgba(0, 0, 0, 0.25);
}

.card__img {
    display: block;
    width: 100%;
    height: 11rem;
    object-fit: cover;
}
.card__content {
    padding: 1rem 1rem;
    height: inherit;
    background-color: $cardBgColor;
    background-image: $cardBgImage;

    .btn {
        background-color: $cardBtnBgColor;
        color:$white;
    }
}
.card__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1rem;
    font-weight: 700;
    color: $white;
    margin-bottom: 1.1rem;
    text-align: center;
    text-transform: uppercase;
  }
  .card__text {
    font-size: 0.8rem;
    line-height: 1.6;
    color: $white;
    margin-bottom: 2rem;
  }

  .card__phone {
    font-size: 1.8rem;
    font-weight: 700;
    text-align: center;
    color: $white;
  }
