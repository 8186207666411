$containerBgColor: #eff7f3;
$headerBgColor: #eff7f3;
$breadcrumbsBgColor: #eff7f3;
$wellBgColor: #fff;
$emailBoxBgColor: #eff7f3;
$dropdownBgColor: #eff7f3;

$itemListBgColor: #fff;

$primaryColor: #85a183;
$secondaryColor: #7bc7f9;

$darkColor: #293742;
$sidebarBgColor: rgba(25, 25, 25, 0.85);

$bgColor: #eff7f3;

$btn-primary: #cae1a5;
$btn-color-primary: #40561b;
$btn-secondary: #a17bf9;

$sidebarMenuBtnBg: #6b5e7d;

$logoBgColor: rgba(242, 87, 67, 1);
$LayoutMainColor: #f5f5f5;
$menuIcons: rgb(91, 27, 97);

//tables
$table-border-color: #ddeaca;

//card
$cardBgColor: transparent;
$cardBgImage: linear-gradient(107deg, rgba(41,43,57,0.9) 2%, rgba(62,49,92,0.75) 42%); 
$cardBtnBgColor: #ec674b;

$black : #525252;
$grey : #dadada;
$orange: orange;
$blue: #0d4166;
$green: rgb(8, 155, 106);
$white: white;
$maroon: #3f3880;
$red: red;


$topMenuIcons: #eb5546;
$hamburgerBtnColor: #333;
