.bp3-button.bp3-intent-primary {
  background-color:$primaryColor;
  box-shadow: none;
}
.bp3-button:hover.bp3-intent-primary {
  background-color:$secondaryColor;
  box-shadow: none;
}
.btn {
    font-family: 'Inter', sans-serif;
    display: block;
    padding: 0.6rem;
    font-size: 0.9rem;
    text-align: center;
    font-weight: 700;
    color: #444;
    background-color:#d7dddf;
    border: none;
    border-radius: .4rem;
    transition: 0.2s;
    cursor: pointer;
    text-decoration: none;
    text-transform: capitalize;
  }
  .btn-primary {
    color: $btn-color-primary;
    background-color: $btn-primary;
  }
  .btn span {
    margin-left: 1rem;
    transition: 0.2s;
    text-decoration: none !important;
  }
  .btn:hover, .card__btn:active {
    color: #fff;
    background-color: #03081a;
    text-decoration: none;
  }
  .btn:hover span, .card__btn:active span {
    margin-left: 1.5rem;
  }

.btn-md {
    font-size: 1em;
    padding: 0.6rem;
    border-radius: .4rem;
  }

.btn-sm {
  font-size: 0.8em;
  padding: 0.6rem;
  border-radius: .2rem;
}

.btn-filter {
  display: block;
    width: 100%;
    padding: 0.5rem;
    font-size: 0.8rem;
    margin-bottom: 15px;

    .bp3-icon {
      margin-right: 10px;
    }
}

.btn-square {
  border: 2px solid #333;
  padding: 7px;
  text-decoration: none;
}
