.search-modal {

    .form-inline  {

        .formGroup {
            align-items: center;
        }
    }

    button {
        color: white;
        width: 22%;
        text-transform: capitalize;
        background-color: #33a59f !important;

        &:hover {
            background-color: #237e79 !important;
        }
    }
 
    input {
        background: none;
        border: none;
        border-bottom:1px solid #ddd;
        border-radius: 0;
        box-shadow: none;
        outline: none;
    }
    
}
