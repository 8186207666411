@import "colors.scss";

.header {
    position: fixed;
    background-color: $headerBgColor;
    width: 100%;
    top: 0;
    z-index: 99;
    font-family: 'Montserrat', sans-serif;
    box-shadow: 0px 3px 15px -6px rgba(185, 185, 185, 0.7);

    &::before {
        display: block;
        position: absolute;
        top: 0;
        z-index: 0;
        width: 100%;
        height: 5px;
        content: "";
        background-color: #2e7a42d9;
    }

    .header-content {
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        transition: all;

        a {
            display: flex;
            align-items: center;
        }
    }

    .menu {
        display: flex;
        justify-content: space-evenly;
        list-style-type: none;
        height: 100%;
        margin: 0;
        padding: 0;

        button {
            background: none;
            border: none;
            margin: 0 15px;
        }

        li {
            display: flex;

            &:hover {
                box-shadow: inset 0px 5px 0px 0px #273a57;
            }

            & > a {
                display: flex;
                align-items: center;
                color: #666;
                font-size: 13px;
                font-weight: 700;
                margin: 0 25px;
                padding: 0;
                text-decoration: none;
                text-transform: uppercase;

                &:hover {
                    color: #4a6424;
                }
            }

            .mega-menu-full {
                position: absolute;
                visibility: collapse;
                display: none;
                opacity: 0;
                
                box-sizing: border-box;
                background: $dropdownBgColor;
                padding: 0;
                box-shadow: none;
                border-radius: 0;
                border: 0;
                color: rgb(236, 236, 236);
                top: 100%;
                z-index: 99;
                box-shadow: 0px 3px 15px -6px rgba(175, 175, 175, 0.5);

                a {
                    width: fit-content;
                    width: -webkit-fill-available;
                    font-size: 0.8em;
                    padding: 8px 20px;
                    margin: 2px;
                    color: #666;
                    display: inline-block;
                    text-transform: lowercase;
                    text-decoration: none;

                    &:first-letter {
                        text-transform: uppercase;
                    }

                    &:hover {
                        color: #4a6424;
                    }
                }

                .links {
                     display: flex;
                }

                .tree {

                    
                    justify-content: space-evenly;

                    div {
                        border-bottom: 1px solid rgb(236, 236, 236);

                        div {
                            padding-left: 10px;
                            border-top: 1px solid rgb(236, 236, 236);
                        }
                    }

                    .child {
                        
                        border-left: none;
                    }
                }

            }

            &:hover {

                .mega-menu-full {
                    display: block;
                    visibility: visible;
                    animation-name: fadeInCollapse;
                    animation-duration: 0.4s;
                    animation-delay: 0.1s;
                    animation-fill-mode: forwards;
                }
            }

        }

    }

    .logo {
        text-align: center;
        padding: 10px 8px 10px 8px;
        font-weight: 700;
        background-color: $white;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }

}


.stickHeader {

    .header-content {
        height: 50px;
    }
 }

.sidebar-menu {
    margin: 0;
    padding: 20px 0;
    list-style-type: none;
    color: $black;
    height: 100%;
    display: flex;
    justify-content: space-between;


    li {
        margin: 30px 0;
        padding: 0;
    }

    a {
        display: flex;
        padding: 15px;
        color: $black;
        font-weight: 700;
        text-decoration: none;
    }

    span {
        margin: 0 5px;
    }

}

.sidebar-menu a.active,
.sidebar-menu a.selected,
.sidebar-menu a:hover {
    background-color: $sidebarMenuBtnBg;
    border-radius: 4px;
    color: $white;
    box-shadow: 0px 3px 15px -6px rgba(0, 0, 0, 0.25);
}


.hamburger-menu {
    display: none;
    width: 20px;
    border-radius: 50%;
    padding: 5px;
    transition: all .2s;
    color: white;
    text-align: center;
    cursor: pointer;

    .menu {
        margin-top: 50px;

        li {
            padding: 10px 0;
        }

        li:nth-child(6) {
            float: right;
            border: none;
            margin-top: 20px;
        }

        li:nth-child(7) {
           float: left;
           border:none;
           margin:20px 0 0 20px;
        }
    }

    .search-modal {
        margin: 100px 25px 0 25px;
        align-items: center;

        .form-inline {
            .formGroup {
                display: flex;
            }
            
        }
    }

}

.hamburger-menu-big {
    z-index: 9999;
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    border-radius: 0;
    color: #333;
    background: white;
}

.hamburger-menu-big .hamburger-menu-btn {
    border-radius: 50%;
    padding: 15px;
    position: absolute;
    top: 4%;
    right: 6%;
    background-color: #fff;
}

.tree {
    margin: 0;
    padding: 0;

    list-style-type: none;

    ul {
        margin: 0 0 0 10px;
        padding: 0;
    }

    a {
        display: block;
        margin: 0 0 0 10px;
        padding: 5px;
        font-weight: 700;
        color: $primaryColor;
    }
}


@keyframes fadeInCollapse {
    0% {
        display: none;
        opacity: 0;
    }

    1% {
        display: block;
        opacity: 0;
        height: auto;
    }

    100% {
        display: block;
        opacity: 1;
        height: auto;
    }
}