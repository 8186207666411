.dropdown {
	position: relative;
	display: flex;
    align-items: center;
    text-transform: uppercase;
	
	.dropdown-btn {
		font-size: 0.9em;
		font-weight: 700;
	}
}

.dropdown ul {
    position: absolute;
	visibility: collapse;
	height: 0;
    opacity: 0;
    
    list-style-type: none;
    margin: 0;
    padding: 0;
    top: 70px;
    right: 0px;
    width: 200px;
   
    font-weight: bold;
    left: 0 !important;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 50;

	li {
		display: flex;
		font-size: 12px;
		align-items: center;
		background-color: white;
		padding: 8px 16px !important;

		img {
			margin-right: 5px;
		}
	}
}

.dropdown-arrow-up {
	margin: -6px 0 0 4px;
	width: 0;
	height: 0;
	border-left: 6px solid transparent;
	border-right: 6px solid transparent;
	border-bottom: 6px solid #ddd;
}

.dropdown-show ul {
    visibility: visible;
	animation-name: fadeInCollapse;
	animation-duration: 0.6s;
	animation-delay: 0.1s;
	animation-fill-mode: forwards;
 }

.dropdown li span {
   cursor: pointer;
}

.dropdown li:first-child:before {
	position: absolute;
	top:-15px;
	left: 0;
	content: '';
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 10px 15px 10px;
	border-color: transparent transparent #fff transparent;
}

.dropdown li:last-child {
    border-bottom: none;
}

.dropdown li:hover {
    background-color: #ddd;
    color: #333;
}

.dropdown .button {
    cursor: pointer;
    padding: 8px;
	color: #333;
}

.dropdown .button:before {
    content: "";
}

@keyframes fadeInCollapse {
	0% {
		display:none;
		opacity: 0;
	}
	1% {
		display: block;
		opacity: 0;
		height: auto;
	}
	100% {
		display: block ;
		opacity: 1;
		height: auto;
	}
}

@keyframes fadeOutCollapse {
	0% {
		display: block;
		opacity: 1;
	}
	99% {
		opacity: 0;
	}
	100% {
		display: none;
		opacity: 0;
		height: 0;
		margin: 0;
	}
}