.table {
  font-size: 0.9em;
  margin: 0;
  padding: 0;
  border: 1px solid $table-border-color;
  border-collapse:collapse;
  border-spacing:0;
 
  caption {
    font-weight: 700;
    font-size: 1em;
    padding: 10px 0;
    color: #666;
  }

  thead {

    th {
      border-bottom: 2px solid $table-border-color;
    }
  }
}

.table th {
  color: #677a8a;
  padding: 1em;
  margin: 0;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 700;
}

.table td {
  padding: 0.4em;
  border-left: 1px solid $table-border-color;
  border-bottom: 1px solid $table-border-color;
  text-align: left;
}

.table td:first-child {
  border-left: none;
}

.table tfoot td {
  text-align: left;
  padding: 1em;
}